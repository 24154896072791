

export const appNames = {
    ControlCenter: 'ControlCenter',
    CriticalComms: 'CriticalComms',
    RiskIntelligence: 'RiskIntelligence',
    AssetUI: 'AssetUI',
    HistoricalReporting: 'HistoricalReporting',
    IncidentManagement: 'IncidentManagement'
};
