import {
    FBA_allowObservability
} from 'common/constants/permissions';


const canUseDatadogRUM = permissions => !!permissions[FBA_allowObservability];


export default {
    canUseDatadogRUM,
};
