import { useAccountContext } from '@context/AccountContext';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import controlCenterNav from '@menu/ControlCenterNav';
import configService from '@services/config.service';
import OrganizationService from '@services/organization.service';
import { ArrowRightIcon, BoxArrowUpRightIcon, HamburgerIcon } from '@onsolve/onsolve-ui-components';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Nav.module.scss';
import PermissionService from '@services/permission.service';
import jwtService from '@services/jwt.service';
const isMenuOrSubMenuSelected = (path, item) => {
    if (item.secondary?.find((s) => s.to === path))
        return item.opened;
    if (item.to === path)
        return !item.opened;
    return false;
};
const isRiskIntelligencePath = (path) => path === '/riskintelligence';
const defaultMenuItems = controlCenterNav;
const NavBar = ({ riskIntelligenceAccount, riskIntelligenceConfigurations }) => {
    const organizationService = new OrganizationService(configService.ControlCenterApiUrl);
    const location = useLocation();
    const { i18n } = useLingui();
    const { brand } = useAccountContext();
    const [customNavigation, setCustomNavigation] = useState(undefined);
    const { canViewWTPCustomer, canViewAssetDataStore } = new PermissionService().permissions;
    const isWTPCustomer = canViewWTPCustomer();
    const handleIsSelected = (path) => !isRiskIntelligencePath(path) && path === location.pathname;
    const [isRIContactUser, setIsRIContactUser] = useState(true);
    const [menuItems, setMenuItems] = useState(defaultMenuItems.map((item) => ({
        ...item,
        selected: handleIsSelected(item.to),
        // If the item or any secondary items (sub menu) are selected, then open the sub menu.
        opened: item.to === location.pathname || !!item.secondary?.find((s) => s.to === location.pathname)
            ? true
            : item.opened,
        secondary: item.secondary?.map((secondary) => ({
            ...secondary,
            selected: secondary.to === location.pathname,
        })),
    })));
    const [expanded, setExpanded] = useState(localStorage.getItem('nav_bar_expansion') !== 'false');
    useEffect(() => {
        // Only trigger if the location has changed
        if (menuItems.find((item) => item.selected)?.to !== location.pathname &&
            menuItems.flatMap((item) => item.secondary)?.find((item) => item?.selected)?.to !== location.pathname) {
            setMenuItems(menuItems.map((item) => ({
                ...item,
                selected: handleIsSelected(item.to),
                // If the item or any secondary items (sub menu) are selected, then open the sub menu.
                opened: item.to === location.pathname || !!item.secondary?.find((s) => s.to === location.pathname)
                    ? true
                    : item.opened,
                secondary: item.secondary?.map((secondary) => ({
                    ...secondary,
                    selected: secondary.to === location.pathname,
                })),
            })));
        }
    }, [location]); //eslint-disable-line
    const hideFromRIBrand = ['/critical_communications', '/alerts/inbox', '/alerts/linked-alerts', '/contacts/groups'];
    useEffect(() => {
        const isRIOnlyBrand = brand === 'riskintelligence';
        setMenuItems(menuItems.map((item) => ({
            ...item,
            canAccess: isRIOnlyBrand && hideFromRIBrand.includes(item.to) ? false : item.canAccess,
            secondary: item.secondary?.map((secondaryItem) => ({
                ...secondaryItem,
                canAccess: isRIOnlyBrand && hideFromRIBrand.includes(secondaryItem.to) ? false : secondaryItem.canAccess,
            })),
        })));
    }, [brand]);
    const riskIntelligencePermissionCheck = ({ to: path = '', canAccess }) => {
        if (isRIContactUser) {
            return false;
        }
        if (path.includes('riskintelligence/travel')) {
            return riskIntelligenceConfigurations?.travel;
        }
        if (path.includes('/riskintelligence')) {
            return !!riskIntelligenceAccount;
        }
        return canAccess;
    };
    useEffect(() => {
        setMenuItems([
            ...menuItems.map((item) => ({
                ...item,
                canAccess: item.to.includes('riskintelligence')
                    ? riskIntelligencePermissionCheck(item)
                    : item.canAccess,
                secondary: item.to.includes('riskintelligence') && !!riskIntelligenceAccount
                    ? item.secondary?.map((secondaryItem) => ({
                        ...secondaryItem,
                        canAccess: riskIntelligencePermissionCheck(secondaryItem),
                        to: secondaryItem.to.includes('riskintelligence/account')
                            ? `/riskintelligence/account/${riskIntelligenceAccount?.account_id}`
                            : secondaryItem.to,
                    }))
                    : item.secondary,
            })),
            ...[
                customNavigation && !menuItems.find((item) => item.externalRoute === customNavigation.url)
                    ? {
                        to: '',
                        externalRoute: customNavigation.url,
                        title: customNavigation.displayText,
                        icon: <BoxArrowUpRightIcon />,
                        selected: false,
                        canAccess: true,
                    }
                    : undefined,
            ].filter(Boolean),
        ]);
    }, [riskIntelligenceAccount, riskIntelligenceConfigurations, customNavigation, isRIContactUser]); //eslint-disable-line
    useEffect(() => {
        const getNavigation = async () => {
            const customNavigation = await organizationService.GetCustomNavigation();
            if (customNavigation?.organizationUUID) {
                setCustomNavigation(customNavigation);
            }
        };
        //these methods are useful only for getting RI user details, will return undefined in non-RI accounts
        const getUserDetails = async () => {
            const orgUuid = jwtService.getOrganizationUuid();
            const userAssetId = canViewAssetDataStore() ? jwtService.getUserAssetId() : jwtService.getUserUuid();
            const userDetails = await organizationService.GetUserDetailsByAssetId(orgUuid, userAssetId);
            setIsRIContactUser(!userDetails?.isAdmin && !userDetails?.isOperator);
        };
        getNavigation();
        getUserDetails();
    }, []);
    const toggleMenu = useCallback((path) => {
        setMenuItems(menuItems.map((item) => ({
            ...item,
            selected: !isRiskIntelligencePath(path) && item.to === path,
            // If you're toggling the same menu item that's already selected, it'll close the sub menu.
            // Otherwise it leaves it alone
            opened: isMenuOrSubMenuSelected(path, item),
            secondary: item.secondary?.map((secondary) => ({
                ...secondary,
                selected: secondary.to === path,
            })),
        })));
    }, [menuItems]);
    const toggleSecondary = useCallback((path) => {
        setMenuItems(menuItems.map((item) => ({
            ...item,
            opened: item.to === path ? !item.opened : false,
        })));
    }, [menuItems]);
    const toggleNavBar = useCallback(() => {
        const newExpanded = !expanded;
        localStorage.setItem('nav_bar_expansion', newExpanded.toString());
        setExpanded(!expanded);
    }, [expanded]);
    const onMenuClick = (path) => {
        if (!isRiskIntelligencePath(path)) {
            toggleMenu(path);
            return;
        }
        if (expanded) {
            toggleSecondary(path);
            return;
        }
        // When nav bar is collapsed, on click of Risk Intelligence icon expand the nav bar and open secondary menu items
        toggleNavBar();
        setMenuItems(menuItems.map((item) => ({
            ...item,
            opened: item.to === path,
        })));
    };
    return (<div className={`${styles.nav} ${expanded ? styles.expanded : ''}`}>
            <button className={styles.hamburger} id="hamburger" aria-label={t `Menu`} onClick={() => toggleNavBar()}>
                <div className={styles.navIcon}>
                    <HamburgerIcon className={styles['slightly-larger']}/>
                </div>
                {brand === 'codered' ? (<div className={styles.codeRedLogo}></div>) : !isWTPCustomer ? (<div className={styles.onSolveLogoWhite}></div>) : null}
            </button>
            {menuItems.map((item) => item.canAccess && (<div className={styles.navItemContainer}>
                            <div className={`${styles.navItem} ${item.selected ? styles.selected : ''}`} title={expanded ? undefined : i18n._(item.title)}>
                                {item.externalRoute ? (<a href={item.externalRoute} target="_blank" rel="noreferrer" className={`${styles.navItemLink} ${item.selected ? styles.selected : ''}`}>
                                        <div className={styles.navIcon}>{item.icon}</div>
                                        <div className={styles.navTitle}>{i18n._(item.title)}</div>
                                    </a>) : (<Link to={isRiskIntelligencePath(item.to) ? null : item.to} onClick={() => onMenuClick(item.to)} className={`${styles.navItemLink} ${item.selected ? styles.selected : ''}`}>
                                        <div className={styles.navIcon}>{item.icon}</div>
                                        <div className={styles.navTitle}>{i18n._(item.title)}</div>
                                    </Link>)}

                                {!!item.secondary?.filter((s) => s.canAccess).length && (<button onClick={() => toggleSecondary(item.to)} className={`${styles.menuArrow} ${item.selected ? styles.arrowSelected : ''}`}>
                                        <ArrowRightIcon className={item.opened &&
                    (item.opened ||
                        item.selected ||
                        item.secondary.find((inner) => inner.selected))
                    ? styles.open
                    : styles.closed}/>
                                    </button>)}
                            </div>
                            {!!item.secondary?.filter((s) => s.canAccess) && (<div className={`${styles.secondaryNavItems} ${expanded &&
                    item.opened &&
                    (item.opened || item.selected || item.secondary.find((inner) => inner.selected))
                    ? styles.shown
                    : styles.hidden}`}>
                                    {item.secondary
                    ?.filter((s) => s.canAccess)
                    .map((secondary) => (<Link key={secondary.to} to={secondary.to} onClick={() => toggleMenu(secondary.to)} className={`${styles.navItem} ${secondary.selected ? styles.selected : ''}`}>
                                                <div className={styles.navIcon}>{secondary.icon}</div>
                                                <div className={styles.navTitle}>{i18n._(secondary.title)}</div>
                                            </Link>))}
                                </div>)}
                        </div>))}
        </div>);
};
export default NavBar;
