import AuthService from 'services/auth.service';
import namespaceService from 'services/namespace.service';
import jwtService from '@services/jwt.service';
import PermissionService from '@services/permission.service';
import { isProduction } from 'utils/envUtilities';
import { getCurrentApp } from 'common/utility/getCurrentApp';
const initializeDatadogRum = () => {
    if (window.DD_RUM) {
        window.DD_RUM.init({
            clientToken: 'pubdeddc0d40240f50bb5d78e4575962f52',
            applicationId: 'edb3ffa5-1e4d-4e36-8cac-c47c5ca55a12',
            site: 'datadoghq.com',
            service: 'mfe',
            env: localStorage.getItem('environment') ?? 'development',
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'allow',
            //todo: we need each individual app to pass set the version for this to accurately show the image tags, right now only OUI is doing it correctly
            version: VERSION,
            beforeSend: (event) => {
                let currentApp = 'mfe';
                try {
                    currentApp = getCurrentApp();
                }
                catch (error) {
                    console.log('error in getting current app, setting to mfe', error);
                }
                event.service = currentApp;
            },
        });
        const setUserDetailsInDatadog = () => {
            if (!window.DD_RUM) {
                console.error('Datadog RUM is not initialized');
                return;
            }
            const orgUuid = jwtService.getOrganizationUuid();
            const userUuid = jwtService.getUserUuid();
            window.DD_RUM.setUser({
                id: userUuid,
            });
            window.DD_RUM.setGlobalContextProperty('orgId', orgUuid);
            window.DD_RUM.setGlobalContextProperty('namespace', namespaceService.getNamespace());
            window.DD_RUM.setGlobalContextProperty('environment', namespaceService.getOriginWithoutNamespace());
        };
        if (AuthService?.isAuthenticated) {
            setUserDetailsInDatadog();
        }
    }
    else {
        console.error('Datadog RUM not found on window object');
    }
};
//determined by "allowObservability" FF in Tahoe EM
//todo: enable in prod - disabled for now for testing and review in integration only
const { canUseDatadogRUM } = new PermissionService().permissions;
const dataDogEnabled = !isProduction() && canUseDatadogRUM();
if (dataDogEnabled) {
    const scriptId = 'datadog-rum-script';
    if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = 'https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js';
        script.async = false;
        script.onload = initializeDatadogRum;
        script.onerror = () => {
            console.error('Error loading Datadog RUM script');
        };
        document.head.appendChild(script);
    }
    else {
        initializeDatadogRum();
    }
}
