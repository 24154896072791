import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import 'scss/style.scss';
import I18nProvider from 'modules/Common/components/I18n/Provider';
import { I18nProvider as LinguiProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { en } from 'make-plural/plurals';
import { messages } from './locales/en-US/messages';
import RIAccountProvider from './context/riAccountContext';
import AccountProvider from '@context/AccountContext';
import SessionProvider from 'context/sessionContext';
import 'modules/Common/components/DataDog/DataDog';
// ---------------------------------------------------------------------------
// In order to get the Favicon to copy properly, we need to import it.
// Several attempts have been made to not require this import, with no luck.
// Do not delete the Favicon import
// ---------------------------------------------------------------------------
i18n.loadLocaleData({
    'en-US': { plurals: en },
});
i18n.load('en-US', messages);
i18n.activate('en-US');
const providers = (<AccountProvider>
        <SessionProvider>
            {/* Can't be re-rendered since it has timer logic for login/logout */}
            <LinguiProvider i18n={i18n} forceRenderOnLocaleChange={false}>
                <I18nProvider>
                    <RIAccountProvider>
                        <App />
                    </RIAccountProvider>
                </I18nProvider>
            </LinguiProvider>
        </SessionProvider>
    </AccountProvider>);
ReactDOM.render(<React.StrictMode>{providers}</React.StrictMode>, document.getElementById('root'));
