/* eslint-disable linebreak-style */
import { appNames } from 'common/constants/appNames';
import PermissionService from '@services/permission.service';
import { assetUIRoutes } from "common/constants/assetUIRoutes";

const { canAccessAssetUI, canViewHistoricalReporting } = new PermissionService().permissions;

const appParams = [
  {
    appName: appNames.ControlCenter,
    paths: ['/controlCenter', '/'],
    condition: () => true
  },
  {
    appName: appNames.RiskIntelligence,
    paths: ['/riskintelligence'],
    condition: () => true
  },
  {
    appName: appNames.IncidentManagement,
    paths: ['/incident_management'],
    condition: () => true
  },
  {
    appName: appNames.CriticalComms,
    paths: ['/alerts'],
    condition: () => true
  },
  {
    appName: appNames.HistoricalReporting,
    paths: ['/eventreporting'],
    condition: () => canViewHistoricalReporting()
  },
  {
    appName: appNames.AssetUI,
    paths: assetUIRoutes,
    condition: () => canAccessAssetUI(),
  },
];

export const getCurrentApp = () => {
  const currentLocation = window.location?.pathname.toLowerCase() ?? '';


  const {appName} = appParams.find(({paths, condition}) => {
    return paths.some(path => currentLocation.includes(path)) && condition();
  })

  return appName || 'OUI';
}
