import accountSettings from './accountSettings';
import alertCenter from './alertCenter';
import contactManagement from './contactManagement';
import dashboard from './dashboard';
import features from './features';
import globalPermissions from './globalPermissions';
import incidentManagement from './incidentManagement';
import integrations from './integrations';
import reporting from './reporting';
import socialMedia from './socialMedia';
import solutions from './solutions';
import supportAndTraining from './supportAndTraining';
import { profile, settings } from './user';
import dataDog from './dataDog';

export default {
    ...features,
    ...globalPermissions,
    ...contactManagement,
    ...accountSettings,
    ...alertCenter,
    ...integrations,
    ...incidentManagement,
    ...reporting,
    ...solutions,
    ...profile,
    ...settings,
    ...supportAndTraining,
    ...dashboard,
    ...socialMedia,
    ...dataDog
};
